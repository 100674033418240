<template>
  <div class="popup" @touchmove.stop.prevent>
	  <modal-pop ref="modal" @confirm="approve" @cancel="handleClose">
	   <div class="content">{{ content }}</div>
	  </modal-pop>
	  
    <!-- <div class="popup-card  panel-card">
      <div class="title">{{ title }}</div>
      <div class="content">{{ content }}</div>
      <div class="tool">
        <div class="primary-btn" @click="approve">确定</div>
      </div>
      <div class="close-btn" @click="handleClose">
        <img src="@/assets/images/new-close.png" alt="" />
      </div>
    </div> -->
  </div>
</template>
<script>
import { mapState } from "vuex";
import Web3 from "web3";
import { config } from "@/config/accountConfig.js";
import { ethers } from "ethers";
import {
  approveSubmit,
  doSubmitMinthash,
  doSubmitMintReceipt,
} from "@/api/index";
import modalPop from "@/components/modal-pop/modal-pop.vue";
export default {
	components: {
	  modalPop,
	},
  props: {
    title: {
      type: String,
      required: true,
    },
    contract: {
      type: String,
      required: true,
    },
    dst_contract: {
      type: String,
      required: true,
    },
    num: {
      type: String,
      required: true,
    },
    approve_key: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState({
      isModal: (state) => state.isModal,
      address: (state) => state.address,
      inviteLink: (state) => state.inviteLink,
    }),
  },
  created() {
    this.provider = new ethers.providers.Web3Provider(window.ethereum);
    this.web3 = new Web3(window.ethereum);
  },
	mounted() {
		this.$refs.modal.Open({
		  title:this.title,
		  modalType: 3,
		  showCancelBtn: false,
		});
	},
  data() {
    return {
      sort: true,
      approved: 0,
      provider: null,
      showRule: false,
    };
  },
  methods: {
    handleChange(value) {
      this.value = value;
    },

    handleClose() {
      this.$emit("close");
    },
    // 点击确认
    handleOk() {
      this.$emit("ok");
    },

    async approve() {
      console.log(this.address);

      if (this.address == "") {
        this.$toast.fail("請先連接錢包或等待区块链同步完成");
        return;
      }

      let crkContract = new ethers.Contract(
        this.contract,
        config.ERC20_TOKEN_CONTRACT_ABI,
        this.provider.getSigner(0)
      );
      // let allowance = await usdtContract.allowance(this.address, config.CRK_NFT_CONTRACT_ADDRESS);
      // let  amount = ethers.utils.formatUnits(allowance, 18)

      this.$toast.loading({
        duration: 0, // 持续展示 toast
        message: "区块链确认中...",
        forbidClick: true,
        loadingType: "spinner",
      });
      // let amount = "1000000000000000000000000";

      const approveResult = await crkContract.approve(
        this.dst_contract, // 允许让谁调用，可以是别人的账号，也可以是诸如pancakeswap的router address等
        this.num // 最高0xffff（64个F）
      );
      console.log(approveResult);
      this.$toast.clear();

      if (approveResult.hash) {
        console.log(approveResult.hash);

        this.$toast.loading({
          duration: 0,
          message: "区块链确认中...",
          forbidClick: true,
          loadingType: "spinner",
        });

        this.getTransactionReceiptMined(approveResult.hash, 1000).then(
          (receipt) => {
            this.is_minting = false;
            console.log(receipt);

            localStorage.setItem(this.approve_key, 1);

            this.$toast.clear();
          }
        );

        // this.$toast.success('已授权成功，请点击“执行”参与合约');

        this.$toast.loading({
          duration: 1500, // 持续展示 toast
          message: "已授权成功，请点击“立刻兑换”",
          forbidClick: true,
          loadingType: "spinner",
        });
      }

      this.handleClose();
    },

    getTransactionReceiptMined(txHash, interval) {
      const self = this;
      const transactionReceiptAsync = function (resolve, reject) {
        self.web3.eth.getTransactionReceipt(txHash, (error, receipt) => {
          if (error) {
            reject(error);
          } else if (receipt == null) {
            setTimeout(
              () => transactionReceiptAsync(resolve, reject),
              interval ? interval : 500
            );
          } else {
            resolve(receipt);
          }
        });
      };

      if (Array.isArray(txHash)) {
        return Promise.all(
          txHash.map((oneTxHash) =>
            self.getTransactionReceiptMined(oneTxHash, interval)
          )
        );
      } else if (typeof txHash === "string") {
        return new Promise(transactionReceiptAsync);
      } else {
        throw new Error("Invalid Type: " + txHash);
      }
    },

    async checkApprove() {
      const sleep = (delay) =>
        new Promise((resolve) => setTimeout(resolve, delay));

      let loopTimes = 10;
      for (let i = 0; i <= loopTimes; i++) {
        if (this.address) {
          this.docheckApprove();
          break;
        }
        await sleep(1000);
      }
    },
    getCrkNFTKey() {
      return (
        "wcrk_nft_contract:" +
        this.address +
        ":" +
        config.NFT_CONTRACT_ADDRESS +
        ":" +
        config.FXCM_TOKEN_CONTRACT_ADDRESS
      );
    },

    docheckApprove() {
      let key = this.getCrkNFTKey();
      let flag = localStorage.getItem(key);
      console.log("key:" + key);
      if (flag) {
        this.approved = true;
      } else {
        this.approved = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 50;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.15);
  margin: 0 auto;
  max-width: 1025px;
  .popup-card {
    margin-left: 0.4324rem;
    margin-right: 0.4324rem;
    padding: 0.6486rem;
    width: 100%;
    z-index: 20;
    margin-top: 5.4054rem;
    border-radius: 0.5946rem;
    max-height: 5.9729rem;
    position: relative;
    .title {
      font-size: 0.4324rem;
      font-weight: 500;
      color: #333;
      line-height: 0.5135rem;
      text-align: center;
      margin-bottom: 0.8378rem;
    }
    .content {
      font-size: 0.3784rem;
      font-weight: 400;
      line-height: 0.4324rem;
      color: rgba(0, 0, 0, 0.7);
      margin-bottom: 1.0541rem;
    }
    .tool {
      display: flex;
      justify-content: center;
      align-items: center;
      .primary-btn {
        width: 100%;
        text-align: center;
        border-radius: 0.2162rem;
        color: #000000;
        font-weight: bold;
        font-size: 0.3784rem;
        padding: 0.3243rem 1.8919rem;
        border: 0.027rem solid #2422f4;
        background: rgb(166, 255, 225);
      }
    }
    .close-btn {
      position: absolute;
      top: 0.2973rem;
      right: 0.2973rem;
      cursor: pointer;
      img {
        width: 0.6486rem;
        height: 0.6486rem;
      }
    }
  }
}
</style>
