import axios from "axios";
import nProgress from "nprogress";
const request = axios.create({
  timeout: 60000,
});

// 添加请求拦截器
request.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    nProgress.start();
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
request.interceptors.response.use(
  function (response) {
    // 对响应数据做点什么
    nProgress.done();
    return response;
  },
  function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);

//pancake

//post封装
export function doPost(url, data = {}) {
  return new Promise((resolve, reject) => {
    console.log(data);
    if (data.hasOwnProperty("address")) {
      data.address = data.address.toLowerCase();
    }

    // let url2 = url.substring(4);
    // console.log(url);

    // data.xxxbearB = 1;
    // //  data.xid = 774;
    // data.xid = 5;
    axios
      .post(url, data, {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
          crossDomain: true,
          "x-auth": localStorage.auth,
          "x-app": "fxcm",
        },
      })
      .then(
        (response) => {
          // close();
          resolve(response.data);
        },
        (err) => {
          // close();
          reject(err);
        }
      );
  });
}

export default request;
