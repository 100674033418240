// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/bg1.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".main[data-v-08fdeff2]{color:#fff;padding-top:2.6rem;width:100%;height:1875px;background:#0a0a0a;border-radius:24px 24px 24px 24px}.main .top-left[data-v-08fdeff2]{margin-top:-265px;width:238px;height:265px;background:#3fdaa0;border-radius:0 0 0 0;opacity:.4;filter:blur(200px)}.main h1[data-v-08fdeff2],.main p[data-v-08fdeff2]{margin:0}.main .slogon[data-v-08fdeff2]{text-align:center;margin:0 auto;font-size:22px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:100% 100%}.main .num-zone[data-v-08fdeff2]{margin-top:1rem;display:flex;justify-content:space-between}.main .num-zone .item[data-v-08fdeff2]{flex:1;height:100px;margin-right:5px}.main .num-zone .item .title[data-v-08fdeff2]{color:#3fdaa0;height:22px;line-height:19px}.main .num-zone .item .content[data-v-08fdeff2]{color:hsla(0,0%,100%,.8);font-size:13px}.main .num-map[data-v-08fdeff2]{height:300px;width:400px}.main .desc[data-v-08fdeff2]{font-size:.34rem;color:#919aa1;margin-bottom:.91rem}.main .main_btn[data-v-08fdeff2]{width:100%;height:1.26rem;background:#3fdaa0;opacity:1;border-radius:.69rem .69rem .69rem .69rem;text-align:center;line-height:1.26rem;font-size:.4rem;font-weight:600;color:#333;margin-bottom:.46rem}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
