export default {
  ok: "OKEx",
  tower_desc:
    "All users who climb the tower will receive rewards, the higher the climb, the higher the rewards, and it is said that everyone who ascends to the top of the spiral tower will gain wealth and freedom",

  rule_desc: "Roadmap",
  whitepaper: "Whitepaper",
  homepage: "Homepage",
  copy: "Copy",
  contact: "Contract Us",
  partner: "Partner",
  myinvite: "My Invite",
  join: "Join Plan",
  invite: "Invite friend to join and make money",
  copy_invite_link: "Copy Invite Link",
  invite_plan: "Invite Plan",
  fetch_price: "Fetching Price...",
  tab_stake: "Star Stake",
  tab_community: "Star Community",
  tab_team: "Star Team",
  tab_equal: "Star Equal",
  foot_exchange:"Exchange",

  price: "Price",
  star_stat:"Star Stat",
  swap_token: "SwapToken",
  swap_history: "SwapHistory",
  available: "Available",
  reward_team_admin: "Team Reward",
  reward_team_equal: "Equal Reward",
  ido: "IDO",
  limit: "Limit 99",
  foot_node:"Node",
  approve: "Approve",
  buy: "Buy",
  sell: "Sell",
  Confirm: "Confirm",
  stake_confirm: "Stake Confirming",
  unstake: "Unstake",
  stake: "Stake",
  team_upgrade:"Team Upgrade",
  
  connect:"connect",

  node: "Node",
  reward_history: "History",
  pending_reward: "Available Reward",
  acc_reward: "Accumulated Reward",
  stake_to_earn: "Stake to earn ELP",
  withdraw: "Withdraw",
  tab_all: "All",
  tab_staked: "Staked",
  take_assetout:"Withdraw",

  tab_unstaked: "Unstaked",
  reward_stake: "Stake",
  reward_promote: "Invite",
  reward_tower: "Tower Reward",
  acc_spiral_reward: "Accumulated Manage Reward",

  tower_stat:"Tower Stat",
  no_club:"No Club",
  joined_club:"Already Joined",
  reward_team: "Team",
  acc_invite_reward: "Accumulated Invite Reward",
  acc_stake_reward: "Accumulated Stake Reward",
  my_community: "My Community",
  rank: "Comunnity",
  layer9:"Already Top",
  not_decided:"Not Joined",

  acc_tower_reward: "Accumulated Tower Reward",
  acc_team_reward: "Accumulated Team Reward",
  stt_reward_rank: "ELP Reward Rank",
  sts_reward_rank: "STS Reward Rank",
  reward_rank: "Reward Rank",
  invite_rank: "Invite Rank",
  home: "Home",
  max_zone:"Max Zone",
  small_zone:"Small Zone Total",
  show_invite_list:"My Invitation",

  footer_stake: "Stake",
  footer_tower: "Tower",
  footer_rank: "Community",
  tower_sts: "LoginTower to Earn",
  usdt_staked:"USDT Staked",
  keep_tower_level:"Keep Level",

  reward_community:"Manage Reward",
  level:"Level",
  reward_swap:"Swap",
  reward_creator:"Gensis",
  cloud_stt_balance: "ELP Balance",
  local_stt_balance: "Local ELP Balance",
  deposit: "Deposit",
  take: "Take",
  acc_genise_reward:"Genise Share Reward",

  take_sts_reward: "Take STS Reward",
  current_level: "Level",
  current_layer: "Layer",
  current_reward: "Reward",
  day: "Day",
  upgrade_need: "Requirement",
  upgrade_time: "Upgrade Time",
  login_tower: "LoginTower",
  team_login_tower: "Team LoginTower",
  upgrade: "Upgrade",
  cancle_login: "Canele Login",
  agree_team_invite: "Agree Team Invite",
  cancle:"Cancle",
  
  btn1:"introduce",
  btn2:"Ai Bot",
  buyBtm:"Buy",
  invitationPlan:"Invitation Plan",
  pledge:"Stake to earn ELP",
  text1:"Extract income",

  p1: "Build the Future with",
  p2: "Trading ecosystem created based on the FXCM Consensus Community",
  
  p3: "Full Decentralised and Currency Security",
  Join_Now: "Join Now",
  Achievements: "Achievements",
  happy_users: "Happy Users",
  community_assets: "Community Assets",
  build_community: "Build Community",
  p4: "Trading ecosystem, with FXCM consensus community to build and develop together",
  
  p5: "FXCMswap Node",
  p6: "Participate in the construction of the FXCM ecosystem and share benefits and governance rights. Lock up and pledge 31900FXCM for 26 weeks to become an FXCMswap node, and you can receive the following benefits: 1.5000-10000FXCM pledge incentives (the first 1000 nodes will incentivize 10000FXCM, and the 1001th node will incentivize 5000FXCM) 2. FXCMswap Ecological Symbol One space equity NFT 3. Create a FXCMswap trading pair and obtain 10% of the permanent profit of the trading pair",
  
  p7: "Lock",
  p8: "Pending",
    p9:"Taked",
  take: "Take",
  p10: "Refer",
  p11: "Join the community and mint TOKEN for free",
  pool: "Pool",

  p12: "FXCMswap Community",

  p13:"The FXCMswap node community is an important part of the FXCM digital ecosystem! As an open community, members can learn, grow and innovate from each other. Together, we can explore the unlimited potential of the FXCM ecosystem and build a decentralized future together. After recommending 3 FXCMswap pledge nodes, you can apply for the FXCMswap node community! FXCMswap node community benefits 1. Every time a staking node is added to the community, the community leader will receive a community maintenance incentive of 1595 FXCM.2. The FXCMswap development community deploys a fairly minted community token for the FXCMswap node community.Community members can obtain community tokens through mint(minting).",
  
  


  
  p14: "ERC20 Address",
  p15: "Telegram",
  p16: "Telegram Community Link",
  p17: "Office Community",
  tc: "Telegram Community Name",

  apply_now: "Apply",
  p19: "Reward",
  
  p21:"Invite new staking nodes to join and receive 15950 FXCM rewards",


  
};
