<template>
	<van-overlay z-index="9999" :show="show">
		<div class="contents" @click="cancel">
			<div class="modal" @click.stop>
				<h3 class="title tc">{{title}}</h3>
				<template v-if="modalType==1">
					<p class="content">{{content}}</p>
					
				</template>
				<template v-else-if="modalType==2">
					<div class="content" v-html="content"></div>
				</template>
				<template v-else-if="modalType==3">
					<div class="content">
						<slot></slot>
					</div>
				</template>
				
				<div class="flex-b-c" v-if="showBtn">
					<div class="main-btn m-0" @click="confirm">{{confirmBtn}}</div>
					<div v-if="cancelBtn" class="main-btn cancel-btn m-0" style="margin-left: .37rem !important;"
						@click="cancel">
						{{cancelBtn}}
					</div>
				</div>
				<img v-if="!disableCancel" class="clost_btn" src="@/assets/images/icons/close_btn.png" alt="" @click="cancel">
			</div>
		</div>
	</van-overlay>
</template>
<script>
	import {
		Overlay
	} from 'vant';
	export default {
		name: "modal",
		components: {
			'van-overlay': Overlay,
		},
		data() {
			return {
				title: '',
				content: "",
				confirmBtn: "确定",
				cancelBtn: "取消",
				show: false,
				modalType: 1 ,//1确认弹窗  2富文本展示窗  3 slot
				showBtn:true,
				disableCancel:false
			}
		},
		methods: {
			Open(obj) {
				const {
					title,
					content,
					confirmBtn,
					cancelBtn,
					modalType,
					showCancelBtn = true,
					showBtn=true,
					disableCancel
				} = obj;
				this.title = title
				this.content = content
				if (confirmBtn) this.confirmBtn = confirmBtn
				if (!showCancelBtn) this.cancelBtn = ''
				if (modalType) this.modalType = modalType
				if(!showBtn) this.showBtn=showBtn
				this.show = true
				this.disableCancel=disableCancel
			},
			confirm() {
				this.$emit("confirm")
			},
			cancel() {
				if(this.disableCancel) return
				this.$emit("cancel")
				this.show = false
			}
		},
	}
</script>

<style lang="scss" scoped>
	.contents {
		position: fixed;
		height: 100%;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;

		.modal {
			background: #333333;
			border-radius: 0.46rem 0.46rem 0.46rem 0.46rem;
			border: 0.03rem solid #3fdaa0;
			padding: .91rem .46rem;
			padding-bottom: .86rem;
			width: 90%;
			position: relative;

			.title {
				font-size: 0.51rem;
				font-weight: 600;
				color: #3fdaa0;
				margin: 0;
			}

			.content {
				font-size: 0.34rem;
				font-weight: 400;
				color: #bcbcbc;
				margin: .46rem 0 .67rem 0;
				max-height: 70vh;
			}

			.clost_btn {
				position: absolute;
				right: 0;
				top: -.91rem;
				width: .69rem;
				height: .69rem;
			}
		}
	}
</style>