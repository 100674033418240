<template>
  <div class="footer-bar flex justify-center">
    <div class="footer">
      <div
        v-for="(item, index) in bar"
        :class="activeIndex == index ? 'active' : ''"
        class="tabbar"
        :key="index"
        @click="handleJumpBar(item, index)"
      >
        <img
          style="width: 0.69rem; height: 0.69rem"
          :src="activeIndex == index ? item.aicon : item.icon"
          alt=""
        />
        <span :style="{ marginTop: item.top + 'rem' }">{{
          $t(item.name)
        }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import Home from "@/assets/images/tabbar/home.png";
import AHome from "@/assets/images/tabbar/ahome.png";
import Box from "@/assets/images/tabbar/box.png";
import ABox from "@/assets/images/tabbar/abox.png";
import Exchnge from "@/assets/images/tabbar/exchange.png";
import AExchange from "@/assets/images/tabbar/aexchange.png";

import Package from "@/assets/images/tabbar/package.png";
import APackage from "@/assets/images/tabbar/apackage.png";
// import Flash from '@/assets/images/tabbar/flash.png'
// import AFlash from '@/assets/images/tabbar/aflash.png'
import Stats from "@/assets/images/tabbar/community.png";
import AStats from "@/assets/images/tabbar/acommunity.png";
export default {
  data() {
    return {
      bar: [
        {
          name: "home",
          router: "/index",
          icon: Home,
          aicon: AHome,
          title: "home",
          width: 0.7027,
          height: 0.6486,
          top: 0.1351,
        },
        {
          name: "foot_node",
          router: "/node",
          icon: Box,
          aicon: ABox,
          title: "Node",
          width: 0.6486,
          height: 0.7568,
          top: 0.0811,
        },
        {
          name: "foot_exchange",
          router: "",
          icon: Exchnge,
          aicon: AExchange,
          title: "交易",
          width: 0.7838,
          height: 0.7568,
          top: 0.0811,
        },
        {
          name: "pool",
          router: "/encourage",
          icon: Package,
          aicon: Package,
          title: "流动池",
        },
        {
          name: "footer_rank",
          router: "/community",
          icon: Stats,
          aicon: AStats,
          title: "社区",
          width: 0.6486,
          height: 0.6486,
          top: 0.1351,
        },
      ],
      activeIndex: 0,
    };
  },
  mounted() {
    const path = this.$route.path;
    const index = this.bar.findIndex((item) => item.router == path);
    this.activeIndex = index === -1 ? 0 : index;
  },
  methods: {
    handleJumpBar(keys, index) {
      if (index == 3 || index == 2) {
        this.$toast.success("建设中，即将上线");
      } else {
        this.$router.push(keys.router);
        this.activeIndex = index;
        this.$emit("change", keys.title);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.footer-bar {
  width: 100%;
  max-width: 1024px;
  height: 1.5rem;
  position: fixed;
  bottom: 0;
  padding: 0 0.8649rem;
  background-color: #0c0c0c;
  z-index: 100;

  .footer {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    font-size: 0.3243rem;
    font-weight: 400;
    line-height: 0.3784rem;
    color: $tab-text-base;

    .tabbar {
      display: flex;
      flex-direction: column;
      height: 1.3514rem;
      justify-content: center;
      align-items: center;

      img {
        // width: 0.6486rem;
        // height: 0.6486rem;
      }

      span {
        line-height: 0.4595rem;
      }
    }
  }

  .active {
    color: #f6ca8b;
  }
}
</style>
