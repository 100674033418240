export default {
  ok: "xxx",
  tower_desc:
    "质押玩家会获得奖励，登入层级越高获得的奖励越高，传说升入星系顶的人将获得财富自由",
  rule_desc: "路线图",
  whitepaper: "白皮书",
  homepage: "官网",
  copy: "复制",
  contact: "如果你在使用门户或者账户时遇到任何 问题，请及时发送电子邮件给我们",
  partner: "合作伙伴",
  myinvite: "我的邀请",
  join: "加入星系计划",
  invite: "邀请好友加入游戏，一起赚取丰厚奖励",
  copy_invite_link: "复制邀请链接",
  invite_plan: "邀请计划",
  fetch_price: "获取价格中...",
  price: "价格",
  swap_token: "立即兑换",
  swap_history: "兑换记录",
  available: "剩余",
  ido: "创世IDO",
  limit: "限量99个名额",
  approve: "授权",
  buy: "购买",
  sell: "卖出",
  usdt_staked: "质押的USDT",
  foot_node:"節點",
  foot_exchange:"交易",
  connect:"链接钱包",

  apply_now:"立刻申请",
  Confirm: "确定",
  stake_confirm: "质押确认中",
  unstake: "取消质押",
  stake: "立即质押",
  node: "创世节点",
  reward_history: "历史收益",
  pending_reward: "待提取收益",
  acc_reward: "累计收益",
  stake_to_ean: "质押NFT赚取ELP",
  withdraw: "提取收益到本地钱包",
  tab_all: "全部",
  tab_staked: "已质押",
  tab_unstaked: "未质押",
  reward_stake: "质押",
  reward_promote: "推广",
  reward_tower: "星系",
  jon_community:"加入社区",

  tab_stake: "星球质押",
  tab_community: "星链社区",
  tab_team: "星系团队",
  tab_equal: "星盟平行",
  
  star_stat:"星系数据",
  reward_team: "团队",
  stake_to_earn: "质押NFT赚取ELP",
  acc_invite_reward: "累计推荐激励",
  acc_stake_reward: "星球质押奖励",
  acc_tower_reward: "星球质押奖励",
  acc_team_reward: "星链社区奖励",
  acc_spiral_reward: "星链社区奖励",
  stt_reward_rank: "ELP收益排行",
  sts_reward_rank: "STS收益排行",
  rank: "社区",
  invite_rank: "邀请排行",
  my_community: "我的社区",
  home: "首页",
  show_invite_list:"我的邀请",
  max_zone: "最大社区",
  small_zone: "小区总和",
  keep_tower_level: "保持级别",
  footer_stake: "质押",
  footer_tower: "星系",
  footer_rank: "社区",
  tower_sts: "质押获得STS",
  cloud_stt_balance: "当前ELP",
  local_stt_balance: "本地钱包ELP余额",
  deposit: "存入",
  team_upgrade:"自动匹配",
  take: "提取",
  take_assetout:"转出",
  reward_team_admin: "星系团队奖励",
  reward_team_equal: "星盟平行奖励",
  reward_community: "管理奖",
  level: "等级",
  tower_stat:"星系数据",
  reward_swap: "交易分红",
  reward_creator: "创世分红",
  acc_genise_reward: "创世分红收益",
  take_sts_reward: "提取STS奖励",
  current_level: "社区等级",
  current_layer: "已登入星系",
  no_club:"暂无社区",
  joined_club:"已加入社区",
  not_decided:"未质押",
  day: "天",
  layer: "层",
  upgrade_need: "升级需要",
  upgrade_time: "升级时间",
  login_tower: "加入星系",
  team_login_tower: "组队质押",
  upgrade: "升级",
  cancle_login: "取消质押",
  agree_team_invite: "同意组队邀请",
  next_layer_usdt: "下一行星需要门票价值",
  layer9: "恭喜您已经进入星系顶",
  current_reward: "当前收益",
  current_layer_usdt: "门票面值",
  current_layer_stt: "本轮投入",
  
  btn1:"玩法介绍",
  btn2:"智能助手",
  buyBtm:"买入",
  invitationPlan:"邀请激励计划",
  pledge:"质押获得ELP",
  text1: "提取收益",

  p1: "共创未来",
  p2: "基于福汇共识社区创建的交易生态系统",
  p3: "完全去中心化和货币安全",
  Join_Now: "加入",
  Achievements: "成就",
  happy_users: "快乐的用户",
  community_assets: "社区资产",
  build_community: "建立社区",
  p4: "交易生态系统，与FXCM共识社区共建、共同发展",

  p5: "FXCMswap节点介绍",
    p6:" 參與FXCM生態建設,共享收益和治理權益.鎖倉質押31900FXCM26週成为FXCMswap節點,可獲得如下收益:1.5000—10000FXCM的質押激勵(前1000個節點激勵10000FXCM,1001節點開始激勵5000FXCM)2.FXCMswap生態Symbol space權益NFT一枚 3.創建FXCMswap交易對, 獲取該交易對10% 的永久性分潤",
    

  p7: "锁定",
  p8: "待领取",
  p9: "已提取",
  p10: "推薦人:(可選)",
  p11: "加入社区，免费铸造TOKEN",
  pool: "流动池",
  
  p12: "FXCMswap节点社区",
  
  p13:"FXCMswap節點社區是FXCM數字生態的重要組成部分!  作為一個開放的社區,讓成員能夠互相學習,成長和創新.共同探索FXCM生態的無限潛力,一起構建去中心化的未來. 推薦3個FXCMswap質押節點後均可申請FXCMswap節點社區! FXCMswap節點社區福利 1.社區每增加一個質押節點 ,該社區長均可獲得1595FXCM的社區維護激勵.  2. FXCMswap開發社區為FXCMswap節點社區部署一個公平鑄造的社區token 社區成員均可通過mint(鑄造)獲得社區代幣",


  p14: "ERC20地址",
  p15: "Telegram賬戶",
  p16: "Telegram社區鏈接",
  p17: "官方社群",
  tc: "Telegram社區名稱",
  
  p19: "剩余奖励",
  cancle: "取消",
  p21:"邀请新质押节点加入，可获15950FXCM奖励",
    

  
};
