import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/styles/global.scss'
import '@/lib/flexiable'
import VueI18n from 'vue-i18n'
import zh from './i18n/zh'
import en from './i18n/en'
import 'vant/lib/index.css';


Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: localStorage.getItem('lang') || 'zh', //没有就设置默认值zh
  messages: {
    zh: zh,
    en: en,
  },
  silentTranslationWarn: true,
})
Vue.config.productionTip = false
import { Dialog } from 'vant'

// 全局注册
Vue.use(Dialog)

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

// import { Toast, Slider } from "vant";
// Vue.use(Toast).use(Slider);

import Toast from 'vant/lib/toast'
import 'vant/lib/toast/style'
Vue.use(Toast)

const bus = new Vue()
Vue.prototype.$bus = bus

Vue.prototype.$userInfo=JSON.parse(localStorage.getItem("userInfo"))


new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
