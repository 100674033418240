<template>
  <div class="main pd-30">

    <div class="top-left">

    </div>

    <div class="slogon">
      <h3>{{ $t("p1") }}</h3>
      <h1>{{ $t("p2") }}
      </h1>

    </div>

    <div class="join_zone">
      <img src="@/assets/bg2.png" style="width: 100%; margin: 0.91rem 0 0.5rem 0" alt="" />

      <p class="desc tc" style="margin-bottom: 0.34rem">{{ $t("Join_Now") }}</p>
      <div style="padding: 0 0.34rem">
        <div class="main_btn" @click="toNode">
          {{ $t("Join_Now") }}
        </div>

      </div>

    </div>
    <div style="text-align: center">
      <h2>{{ $t("p3") }}</h2>
      <h1>{{$t("Achievements")}}</h1>

      <div class="num-zone">

        <div class="item">
          <div class="title">
            2M+
          </div>
          <div class="content">
            
            {{ $t("happy_users") }}
          </div>
        </div>

        <div class="item">
          <div class="title">
            310K+
          </div>
          <div class="content">
            {{ $t("community_assets") }}
          </div>
        </div>

        <div class="item">
          <div class="title">
            30K+
          </div>
          <div class="content">
            
            {{ $t("build_community") }}
          </div>
        </div>


      </div>
      <img src="@/assets/bg3.png" style="width: 100%; margin: 0.1rem 0 0.1rem 0" />



      <div style="text-align: center">
        <h1>FXCMSwap</h1>
        <h3>{{$t("p4")}}</h3>
      </div>

    </div>

  </div>
</template>


<script>
import { mapState } from "vuex";
import { config } from "@/config/accountConfig.js";
import { ethers } from "ethers";
import Web3 from "web3";

export default {
    components: {

    },
    data() {
        return {
            refer: "",
            check: "checked"

        };
    },
    computed: {
        ...mapState({
            isModal: (state) => state.isModal,
            address: (state) => state.address,
        }),
    },
    created() {
        this.web3 = new Web3(window.ethereum);
        this.provider = new ethers.providers.Web3Provider(window.ethereum);

    },
    mounted() {
        // this.$refs.modal.Open({
        //      title: '标题标题',
        //      content: "内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容",
        // })
        this.web3 = new Web3(window.ethereum);
        this.provider = new ethers.providers.Web3Provider(window.ethereum);

        this.$bus.$on("update_stat", (val) => {
            console.log("update_log");
            // this.syncBalance();
            // this.getStat();
        });
    },
  methods: {
    toNode() {
      this.$router.push("/node");
        
      },

        take_reward() {

        },

    }

};
</script>


<style lang="scss" scoped>
.main {
  color: white;
  // background-image: url("@/assets/images/images/bg.png");
  // background-size: 100% 100%;
  padding-top: 2.6rem;
  width: 100%;
  height: 1875px;
  background: #0A0A0A;
  border-radius: 24px 24px 24px 24px;

  .top-left {
    margin-top: -265px;
    width: 238px;
    height: 265px;
    background: #3FDAA0;
    border-radius: 0px 0px 0px 0px;
    opacity: 0.4;
    filter: blur(200px);
  }

  p,
  h1 {
    margin: 0;
  }

  .slogon {
    text-align: center;
    margin: 0 auto;
    font-size: 22px;
    background-image: url("@/assets/bg1.png");
    background-size: 100% 100%;
  }

  .join_zone {}

  .num-zone {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;

    .item {
      flex: 1;
      height: 100px;
      margin-right: 5px;

      .title {
        color: #3FDAA0;
        height: 22px;
        line-height: 19px;
      }

      .content {
        color: rgba(255, 255, 255, 0.8);
        font-size: 13px;

      }
    }
  }

  .num-map {
    height: 300px;
    width: 400px;

  }

  .desc {
    font-size: 0.34rem;
    color: #919aa1;
    margin-bottom: 0.91rem;
  }

  .main_btn {
    width: 100%;
    height: 1.26rem;
    background: #3FDAA0;
    opacity: 1;
    border-radius: 0.69rem 0.69rem 0.69rem 0.69rem;
    text-align: center;
    line-height: 1.26rem;
    font-size: 0.4rem;
    font-weight: 600;
    color: #333333;
    margin-bottom: 0.46rem;
  }
}
</style>
