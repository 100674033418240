<template>
  <div class="home" style="padding-bottom: 1rem;">
    <HomeHeader :title="title" />
    <router-view />
    <HomeFooter @change="handleChangeTab" />
  </div>
</template>

<script>
import HomeHeader from '@/components/layout/home-header.vue'

import HomeFooter from '@/components/layout/home-footer.vue'
export default {
  name: 'Home',
  components: {
    HomeHeader,
    HomeFooter,
  },
  data() {
    return {
      title: 'StarToken',
    }
  },
  methods: {
    handleChangeTab(title) {
      this.title = title
    },
  },
}
</script>
