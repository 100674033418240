<template>
  <div class="home-header pd-30 flex flex-b-c">
   
    <div class="fc">
      
      <span class="name">FXCMswap</span>
    </div>
    <div class="fc">
      <van-popover
        v-model="showPopover"
        placement="bottom"
        overlay
        trigger="click"
        :actions="actions"
        @select="changeLanguage"
      >
        <template #reference>
          <img
            :src="actions[cureentLang].icon"
            style="width: 0.69rem; height: 0.69rem"
            alt=""
          />
        </template>
      </van-popover>
      <div class="connect_btn" v-if="!auth" @click="loginwithsign">
        {{ $t("connect") }}
      </div>
      <div class="connect_btn" v-else @click="logout">
        {{ address | formatAddress }}
      </div>
    </div>
	<NewJoinPopup
        title="请先加入"
        content="加入星系计划"
        @close="handleCloseInvite"
        v-if="show_invite"
    />
  </div>
</template>

<script>
import { initWeb3 } from "@/utils/block";
import { login, visizt, doLogout, doSetLang } from "@/api/index.js";
import Web3 from "web3";
import { mapActions, mapState } from "vuex";
import ApprovePopup from "@/components/approve-popup";
import TokenApprovePopup from "@/components/token-approve";
import NewJoinPopup from "@/components/new-join-popup";
import Vue from "vue";

import { Popover } from "vant";

export default {
  name: "home-header",
  components: {
    NewJoinPopup,
    "van-popover": Popover,
  },

  props: {
    title: {
      type: String,
      default: "StarToken",
    },
  },

  computed: {
    ...mapState({
      sideShow: (state) => state.sideShow,
    }),
  },
  watch: {
    $route: {
      handler(to, form) {
        console.log("====================================");
        console.log(to.name);
        console.log("====================================");
        this.name = to.name;
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      address: "",
      refcode: "",
      seq: 0,
      web3: null,
      auth: "",
      loading: "",
      is_test: false,
      lang: "En",
      show_invite: false,
      showPopover: false,
      actions: [
        {
          text: "中文",
          id: "zh",
          icon: "/imgs/icons/cn.png",
        },
        {
          text: "En",
          id: "en",
          icon: "/imgs/icons/en.png",
        },
      ],
      cureentLang: 0,
    };
  },
  filters: {
    formatAddress: function (value) {
      if (!value) return "";
      value = value.toString();
      
      return value.substring(0, 4) +"..."+value.substring(39,44);
    },
  },

  async created() {
    let reflink = localStorage.getItem("reflink");
    this.$store.commit("setInviteLink", reflink);

    initWeb3(this.is_test);
    let accounts = await window.ethereum.enable();
    this.web3 = new Web3(window.ethereum);

    this.address = accounts[0];
    this.$store.commit("setAddress", accounts[0]);
    this.refcode = this.$route.query.refcode;

    let params = {
      address: this.address,
    };

    let t = await visizt(params);
    console.log("header===");

    console.log(t);
    this.seq = t.result.seq;

    let saved = localStorage.getItem("address");
    let auth = localStorage.getItem("auth");
    if (saved == this.address && auth != "") {
      this.auth = auth;
    }

    this.checkLoginState();
    this.lang = "En";
    this.$i18n.locale = "zh";
  },
  mounted() {
    this.fn();
  },
  methods: {
    handleCloseInvite() {
      this.show_invite = false;
    },
    changeLanguage(e, index) {
      this.$i18n.locale = e.id;
      this.cureentLang = index;
    },

    ...mapActions(["setLogInState", "setLogOutState"]),

    checkLoginState() {
      let saved = localStorage.getItem("address");
      let auth = localStorage.getItem("auth");
      console.log("saved:", saved);
      console.log("auth", auth);
      if (saved == this.address && auth != "") {
        this.auth = auth;
        this.$store.dispatch("setLogInState");
        console.log("set logIn");
        this.$bus.$emit("update_stat");
      } else {
        localStorage.setItem("address", "");
      }
    },

    handleJumpIndex() {
      this.$router.push("/index");
    },
    async fn() {
      let that = this;
      window.ethereum.on("accountsChanged", function (accounts) {
        console.log(accounts[0]); //一旦切换账号这里就会执行

        // that.$store.commit("setAddress", "");
        that.logout();
        that.address = accounts[0];
        that.$store.commit("setAddress", accounts[0]);
        console.log("fn: " + that.address);
      });
    },

    init() {},
    handleSide() {
      if (this.sideShow) {
        this.$store.commit("setSideShow", false);
      } else {
        this.$store.commit("setSideShow", true);
      }
    },
    async handleChangeLang() {
      let lang = this.$i18n.locale === "zh" ? "en" : "zh";
      this.$i18n.locale = lang;
      let r = await doSetLang({
        lang: lang,
      });
      console.log(r);
    },
    logout() {
      this.auth = "";
      localStorage.setItem("auth", "");
      localStorage.setItem("address", "");
      this.$bus.$emit("update_stat", "");
      doLogout();

      localStorage.clear();
      Vue.prototype.$userInfo = null;
    },
    mycar() {
      this.$router.push("/sports-car");
    },

    loginwithsign() {
      let msg = "Login in(Seq:" + this.seq + ")";
      if (this.address == null || this.address == "") {
        // alert('please refresh page and unlock metamask wallet')
        this.$toast.fail("请耐心等待区块链状态同步");
        return;
      }

      this.$toast.loading({
        message: "等待授权...",
        overlay: true,
        forbidClick: true,
      });

      this.web3.eth.personal
        .sign(msg, this.address)
        .then((res) => {
          console.log("sign repponse:", res);
          let params = {
            sig: res,
            msg: msg,
            seq: this.seq,
            address: this.address,
            refcode: this.refcode,
          };
          login(params)
            .then((ret) => {
              console.log("login ret:", ret);

              localStorage.setItem("auth", ret.result.token);
              localStorage.setItem("address", this.address);
              localStorage.setItem("refcode", ret.result.invite_code);
              localStorage.setItem("reflink", ret.result.invite_link);
              localStorage.setItem("uid", ret.result.uid);
              localStorage.setItem("userInfo", JSON.stringify(ret.result));
              Vue.prototype.$userInfo = ret.result;

              if (ret.result.pid > 0) {
                let key = "sttrefer:" + this.address;
                localStorage.setItem(key, 1);
              }

              this.$store.commit("setInviteLink", ret.result.invite_link);
              this.auth = ret.result.token;
              this.fold = true;
              this.loading = false;

              //location.reload();
              console.log("Login setAddress: " + this.address);
              this.$store.commit("setAddress", this.address);
              this.$store.commit("setParent", ret.result.parent_address);

              this.$store.commit("setInviteLink", ret.result.invite_link);
              this.$store.commit("setUid", ret.result.uid);
              this.$store.commit("setPid", ret.result.pid);

              this.$bus.$emit("update_stat", this.address);

              this.$store.dispatch("setLogInState");
          
              //location.reload();
              console.log(this.auth);

              return;
            })
            .catch((e) => {
              console.log("Login:", e);
              this.loading = false;
              this.loadText = "Unlock wallet";
            });
        })
        .catch((e) => {
          this.$toast.fail("获取签名失败");
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.home-header {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 2.6rem;

  .name,
  .connect_btn {
    margin-left: 0.2rem;
  }

  .name {
    font-size: 0.57rem;
    font-weight: 600;
    color: white;
  }

  .connect_btn {
    width: 2.06rem;
    height: 0.91rem;
    background: #f6ca8b;
    border-radius: 0.11rem 0.11rem 0.11rem 0.11rem;
    font-size: 0.34rem;
    color: #333333;
    text-align: center;
    line-height: 0.91rem;
  }
}
</style>
