// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("assets/PingFang Heavy.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "*{box-sizing:border-box}@font-face{font-family:Heavy;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}body,html{font-style:normal;padding:0;margin:0}#app{min-height:100vh;font-family:Heavy;font-size:.4324rem}#app,.home{max-width:1024px;margin:0 auto;overflow:hidden}.invate_address_input{width:96%;margin:0 auto;margin-top:.5rem;padding:.3rem .5rem;border-radius:50px;outline:none;border:none;font-size:.4rem;color:#000}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
