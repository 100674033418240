import Web3 from "web3";
import { config } from "@/config/accountConfig.js";

export const initWeb3 = (is_test) => {
  is_test = false;
  // if (typeof web3 !== "undefined" && typeof window.ethereum !== "undefined") {
  //   if (is_test) {
  //     window.ethereum
  //       .request({
  //         method: "wallet_addEthereumChain",
  //         params: [
  //           {
  //             chainId: "0x61",
  //             chainName: "BSC_TEST",
  //             nativeCurrency: {
  //               name: "TBNB",
  //               symbol: "TBNB",
  //               decimals: 18,
  //             },
  //             rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
  //             blockExplorerUrls: ["https://testnet.bscscan.com/"],
  //           },
  //         ],
  //       })
  //       .then(() => {
  //         console.log("网络切换成功");
  //         return new Web3(window.web3.currentProvider);
  //       })
  //       .catch((e) => {
  //         console.log(e);
  //       });
  //   } else {
  //     window.ethereum
  //       .request({
  //         method: "wallet_addEthereumChain",
  //         params: [
  //           {
  //             chainId: "0x38",
  //             chainName: "BSC",
  //             nativeCurrency: {
  //               name: "BNB",
  //               symbol: "BNB",
  //               decimals: 18,
  //             },
  //             rpcUrls: ["https://bsc-dataseed.binance.org/"],
  //             blockExplorerUrls: ["https://bscscan.com/"],
  //           },
  //         ],
  //       })
  //       .then(() => {
  //         console.log("网络切换成功");
  //         return new Web3(window.web3.currentProvider);
  //       })
  //       .catch((e) => {
  //         console.log(e);
  //       });
  //   }

    return null;
  };
//    else {
//     // alert("please install MetaMask");
//     return null;
//   }
// };

export const getTransactionReceipt = (txHash, interval) => {
  const self = this;
  const transactionReceiptAsync = function (resolve, reject) {
    self.web3.eth.getTransactionReceipt(txHash, (error, receipt) => {
      if (error) {
        reject(error);
      } else if (receipt == null) {
        setTimeout(
          () => transactionReceiptAsync(resolve, reject),
          interval ? interval : 1000
        );
      } else {
        resolve(receipt);
      }
    });
  };

  if (Array.isArray(txHash)) {
    return Promise.all(
      txHash.map((oneTxHash) => self.getTransactionReceipt(oneTxHash, interval))
    );
  } else if (typeof txHash === "string") {
    return new Promise(transactionReceiptAsync);
  } else {
    throw new Error("Invalid Type: " + txHash);
  }
};
