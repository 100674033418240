import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    sideShow: false,
    isModal: false, // 是否显示弹窗，如果显示添加遮罩
    address: "",
    inviteLink: "",
    uid: 0,
    isLogin: false, //是否登陆
    isPay: false, //是否购买
    initApproved:false,//是否授权
    pid: 0,
    parentAddress:"",

  },
  mutations: {
    setSideShow(state, value) {
      if (value) {
        document.body.style.position = 'fixed'
        document.body.style.width = '100%'
      } else {
        document.body.style.position = 'relative'
        document.body.style.width = '100%'
      }
      state.sideShow = value
    },
    setIsModal(state, value) {
      if (value) {
        document.body.style.position = 'fixed'
        document.body.style.width = '100%'
      } else {
        document.body.style.position = 'relative'
        document.body.style.width = '100%'
      }
      state.isModal = value
    },
    setAddress(state, value) {
      state.address = value;
    },
    setUid(state, value) {
      state.uid = value;
    },
    setPid(state, value) {
      state.pid = value;
    },
    setParent(state,value) {
      state.parentAddress = value
    },
    setInviteLink(state, value) {
      state.inviteLink = value;
    },
    setLogIn(state) {
      state.isLogin = true;
    },
    setLogout(state) {
      state.isLogin = false;
    },
    setLoginState(state) {
      state.isLogin = !state.isLogin;
    },
    setLogged(state) {
      state.isLogin = !state.isLogin;
    },
  },
  actions: {
    setLogInState({ commit }) {
      commit("setLogIn");
    },
    setLogOutState({ commit }) {
      commit("setLogout");
    },
  },
  modules: {},

});
