import { doPost } from "@/utils/request.js";
// 登录/注册
export const login = (params) => {
  return doPost("/up/user/api/passport/loginwithsign", params);
};

export const doTryAddCommunity = async (params) => {
  return doPost("/up/Crk/index/tryAddCommunity", params);
};

export const doGetNodeInfo = async (params) => {
  return doPost("/up/Crk/index/getNodeInfo", params);
};
export const doGetNotie = async (params) => {
  return doPost("/up/Article/api/share/getArticle", params);
};
export const doDepositSubmit = async (params) => {
  return doPost("/up/Crk/index/depositSubmit", params);
};
export const doSwapSubmit = async (params) => {
  return doPost("/up/Crk/index/swapSubmit", params);
};
export const doSwapList = async (params) => {
  return doPost("/up/Crk/index/getswaplist", params);
};

export const doGetSwapPrice = async (params) => {
  return doPost("/up/Crk/index/getSwapPrice", params);
};
export const doTransferSTT = async (params) => {
  return doPost("/up/Crk/index/transferSTT", params);
};
export const doTakeStakedRewardSTT = async (params) => {
  return doPost("/up/Crk/index/takeStakedRewardSTT", params);
};
export const doTakeSTS = async (params) => {
  return doPost("/up/Crk/index/takeSTS", params);
};
export const doTakeWalletSTT = async (params) => {
  return doPost("/up/Crk/index/takeAssetSTT", params);
};


export const doGetMyNFTList = async (params) => {
  return doPost("/up/Crk/index/getMyNFTList", params);
};

export const doKeepTowerLevel = async (params) => {
  return doPost("/up/Crk/index/keepTowerLevel", params);
};

export const doSetLang = async (params) => {
  return doPost("/up/User/Api/index/setDefaultLang", params);
};


export const doGetMinerDetail = async (params) => {
  return doPost("/up/Crk/index/getMinerDetail", params);
};
export const doPersonalLogin = async (params) => {
  return doPost("/up/Crk/index/personalLogin", params);
};

export const doGetLoginTowerInfo = async (params) => {
  return doPost("/up/Crk/index/getLoginTowerInfo", params);
};

export const doGetBalanceInfo = async (params) => {
  return doPost("/up/Crk/index/getBalanceInfo", params);
};
export const doAutoBuildTeam =async (params) => {
  return doPost("/up/Crk/index/autoBuildTeam", params);
};

export const doGetPreLoginTowerInfo = async (params) => {
  return doPost("/up/Crk/index/getPrefLoginTowerInfo", params);
};
export const doGetUpgradeTowerInfo = async (params) => {
  return doPost("/up/Crk/index/getUpgradeTowerInfo", params);
};

export const doTeamLoginInTower = async (params) => {
  return doPost("/up/Crk/index/teamLoginInTower", params);
};
export const doQuitTower = async (params) => {
  return doPost("/up/Crk/index/quitTower", params);
};
export const doUpgradeTower = async (params) => {
  return doPost("/up/Crk/index/doUpgradeTower", params);
};

export const doGetLoginTowerDelta = async (params) => {
  return doPost("/up/Crk/index/getLoginTowerDelta", params);
};

export const doGetMyTeam = async (params) => {
  return doPost("/up/Crk/index/getTeamInfo", params);
};
export const doInviteMember = async (params) => {
  return doPost("/up/Crk/index/inviteTeamMember", params);
};

export const doUpgradeTeamInviteMember = async (params) => {
  return doPost("/up/Crk/index/upgradeTeamInviteTeamMember", params);
};
export const doGetTeamBuildingStat = async (params) => {
  return doPost("/up/Crk/index/getTeamBuildingStat", params);
};

export const doTakeReward = async (params) => {
  return doPost("/up/Crk/index/takeReward", params);
};

export const doSwapToken = async (params) => {
  return doPost("/up/Crk/index/swapToken", params);
};

export const doGetRewardList = async (reward_type) => {
  return doPost("/up/Crk/index/getRewardList", { type: reward_type });
};

export const doGetUntakedRewardList = async (reward_type) => {
  return doPost("/up/Crk/index/getUntakedRewardList", {
    type: reward_type,
  });
};

export const doGetMyCommunity = async (params) => {
  return doPost("/up/Crk/index/mycommunity", params);
};


export const doGetTakeList = async (params) => {
  return doPost("/up/Crk/index/getTakeList", params);
};

export const doGetRewardRankList = async (params) => {
  return doPost("/up/Crk/index/getRewardRankList", params);
};
export const doGetInviteRankList = async (params) => {
  return doPost("/up/Crk/index/getInviteRankList", params);
};

export const doGetTeamRewaqrdList = async (params) => {
  return doPost("/up/Crk/index/getTeamRewardList", params);
};
//
export const doGetInviteRewardList = async (params) => {
  return doPost("/up/Crk/index/myInviteRewardList", params);
};

export const doLogout = (params) => {
  return doPost("/up/user/api/passport/logout", params);
};

export const getNonce = (params) => {
  return doPost("/up/user/api/passport/getNonce", params);
};

export const visizt = async (params) => {
  return doPost("/up/user/api/passport/visizt", params);
};

export const doWidthdrawNftReward = (params) => {
  return doPost("/up/Crk/index/withdrawnftreward", params);
};

export const doGetHomeStat = async (params) => {
  return doPost("/up/Crk/index/doGetHomeStat", params);
};

export const doGetPrice = (params) => {
  return doPost("/up/Crk//share/getPrice", params);
};

export const doGetMyCarList = (params) => {
  return doPost("/up/Crk//index/getMyCarList", params);
};

export const doTransfer = async (params) => {
  return doPost("/up/Crk/index/transfer", params);
};

export const doNFTStakeApprove = async (params) => {
  return doPost("/up/Crk/index/nftstakeapprove", params);
};

export const doswap = async (params) => {
  return doPost("/up/Crk/index/doswap", params);
};

//赎回
export const doWidthdraw = (params) => {
  return doPost("/up/Crk/index/withdraw", params);
};

export const approveSubmit = async (params) => {
  return doPost("/up/Crk/index/approve", params);
};
export const doAddRefer = async (params) => {
  return doPost("/up/Crk/index/addRefer", params);
};

export const doGetDirectRewardList = async (params) => {
  return doPost("/up/Crk/index/getdirectrewardlist", params);
};

export const isApproved = async (params) => {
  return doPost("/up/Crk/index/isApproved", params);
};

export const doLpStakeApprove = async (params) => {
  return doPost("/up/Crk/index/lpStakeApprove", params);
};

export const mintSubmit = async (params) => {
  return doPost("/up/Crk/index/mint", params);
};

export const tryUseCode = async (params) => {
  return await doPost("/up/Crk/index/tryUseCode", params);
};

export const getRewardList = async (params) => {
  return await doPost("/up/Crk/index/getRewardList", params);
};

export const doGetMyApprove = async (params) => {
  return await doPost("/up/Crk/index/getMyApprove", params);
};

export const doSubmitMinthash = async (params) => {
  return doPost("/up/Crk/index/submitMinthash", params);
};
export const getAddressByRefer = async (params) => {
  return doPost("/up/Crk/index/getAddressByRefer", params);
};

export const doSubmitMintReceipt = async (params) => {
  return doPost("/up/Crk/index/submitMintReceipt", params);
};

export const doStartMiner = async (params) => {
  return doPost("/up/Crk/index/doStartMiner", params);
};

export const doAddBalance = async (params) => {
  return doPost("/up/Crk/index/doAddBalance", params);
};
export const doGetBoxBalance = async (params) => {
  return doPost("/up/Crk/index/getBoxBalance", params);
};

export const doAddBoxBalance = async (params) => {
  return doPost("/up/Crk/index/incBalance", params);
};

export const doCheckTokenHash = async (params) => {
  return doPost("/up/Crk/index/checkTokenHash", params);
};

export const doGetNoticeList = async (params) => {
  return doPost("/up/Article/Api/Share/doGetNoticeList", params);
};
export const doGetPid = async (params) => {
  return doPost("/up/Crk/index/getPid", params);
};

export const doGetCodeBox = async (params) => {
  return doPost("/up/Crk/index/getCodeBox", params);
};
export const doOpenCodeBox = async (params) => {
  return doPost("/up/Crk/index/openCodeBox", params);
};
export const doGetDirectReward = async (params) => {
  return doPost("/up/Crk/index/doGetDirectReward", params);
};

export const doGetMyAsset = async (params) => {
  return doPost("/up/Crk/index/doGetMyAsset", params);
};

export const doAddLiquidy = async (params) => {
  return doPost("/up/Crk/index/addLiquid", params);
};
export const doDelLiquidy = async (params) => {
  return doPost("/up/Crk/index/delLiquid", params);
};

export const doWithdraw = async (params) => {
  return doPost("/up/Crk/index/doWithdraw", params);
};

export const doStakeApproveUSDT = async (params) => {
  return doPost("/up/Crk/index/stakeusdtapprove", params);
};

export const doStakeUSDT = async (params) => {
  return doPost("/up/Crk/index/stakeusdt", params);
};
export const doTakenftreward = async (params) => {
  return doPost("/up/Crk/index/takenftreward", params);
};

export const doGetStakeUSDTList = async (params) => {
  return doPost("/up/Crk/index/usdtstakelist", params);
};
export const doStakeUSDTReward = async (params) => {
  return doPost("/up/Crk/index/usdtstakereward", params);
};

export const doGetStakeUSDTReward = async (params) => {
  return doPost("/up/Crk/index/getusdtstakereward", params);
};

export const doStakeUSDTTake = async (params) => {
  return doPost("/up/Crk/index/takeusdtreward", params);
};

export const doAlreadyStakedUsdt = async (params) => {
  return doPost("/up/Crk/index/alreadyStakedUsdt", params);
};

export const doStakeUSDTWithdraw = async (params) => {
  return doPost("/up/Crk/index/takeusdt", params);
};

export const doGetUSDTAPY = async (params) => {
  return doPost("/up/Crk/index/usdtapy", params);
};

export const doGetMyNFT = async (params) => {
  return doPost("/up/Crk/index/rentlist", params);
};

export const doGetRentList = async (params) => {
  return doPost("/up/Crk/index/rentlist", params);
};
export const doStakeNFT = async (params) => {
  return doPost("/up/Crk/index/stakeNFT", params);
};
export const doGetStakedList = async (params) => {
  return doPost("/up/Crk/index/stakelist", params);
};
export const doUnStakeNFT = async (params) => {
  return doPost("/up/Crk/index/unstakeNFT", params);
};

export const doGetLp = async (params) => {
  return doPost("/up/Crk/index/getlplist", params);
};

export const doGetPowerStat = async (params) => {
  return doPost("/up/Crk/index/getpowerstat", params);
};

export const doCheckAvailabeBalance = async (params) => {
  return doPost("/up/Crk/index/checkAvailableBalance", params);
};

export const doGetMyPowerReward = async (params) => {
  return doPost("/up/Crk/index/getmypowerreward", params);
};
export const doSubmitTake = async (params) => {
  return doPost("/up/Crk/index/submitTake", params);
};

export const doGetStakeReward = async (params) => {
  return doPost("/up/Crk/index/getStakeReward", params);
};
export const doGetCarRank = async (params) => {
  return doPost("/up/Crk/index/getCarRank", params);
};
export const doGetPowerRank = async (params) => {
  return doPost("/up/Crk/index/getPowerRank", params);
};
export const doGetStakeRank = async (params) => {
  return doPost("/up/Crk/index/getStakeRank", params);
};

export const doGetNFTRank = async (params) => {
  return doPost("/up/Crk/index/doGetNFTRank", params);
};
//直推第二个盲盒，拿50%，但需要推荐人复投50U。
export const doSubmitFutouHash = async (params) => {
  return doPost("/up/Crk/index/doSubmitFutouHash", params);
};
export const doSubmitFutouReceipt = async (params) => {
  return doPost("/up/Crk/index/doSubmitFutouReceipt", params);
};
export const doGetMyCode = async (params) => {
  return doPost("/up/Crk/index/doGetMyCode", params);
};
export const doGetMyProfit = async (params) => {
  return doPost("/up/Crk/index/doGetMyProfit", params);
};

export const doGetCashlist = async (params) => {
  return doPost("/up/Crk/index/cashlist", params);
};

export const doGetMyInvitelist = async (params) => {
  return doPost("/up/Crk/index/myinvitelist", params);
};
export const doGetTeamInvitelist = async (params) => {
  return doPost("/up/Crk/index/teaminvitelist", params);
};

export const getRewards = async (params) => {
  return doPost("/up/Crk/index/getRewards", params);
};

export const doGetSwapList = async (params) => {
  return doPost("/up/Crk/index/getSwapList", params);
};

// 普通池首页
export const ordinaryHome = (params) => {
  return doPost("/up/index/userhome", params);
};
// 董事会首页
export const directorsHome = (params) => {
  return doPost("/up/index/shareholderhome", params);
};

// 分享
export const shareByPn = (params) => {
  return doPost("/up/index/share", params);
};
// 开启新矿
export const stake = (params) => {
  return doPost("/up/index/stake", params);
};
// 保存用户的操作hash
export const saveUserHash = (params) => {
  return doPost("/up/index/saveTxid", params);
};
// 记录所有hash值
export const saveAllHash = (params) => {
  return doPost("/up/index/saveTxid", params);
};

// 提取收益
export const takeprofit = (params) => {
  return doPost("/up/index/takeprofit", params);
};

// 设定分红比例
export const setratio = (params) => {
  return doPost("/up/admin/dividendSet", params);
};

export const cashList = (page, params) => {
  params["page"] = page;
  return doPost("/up/index/cashlist", params);
};

export const adminDividendList = (page, params) => {
  params["page"] = page;
  return doPost("/up/admin/dividendlist", params);
};
export const List = (page, params) => {
  params["page"] = page;
  return doPost("/up/admin/cashlist", params);
};

//待处理的审核列表
export const authList = (page, params) => {
  params["page"] = page;
  return doPost("/up/admin/authList", params);
};

//获取stake数量
export const getUserStake = (params) => {
  return doPost("/up/index/getUserStake", params);
};

//获取stake数量
export const doAgree = (params) => {
  return doPost("/up/admin/agree", params);
};

//获取stake数量
export const doReject = (params) => {
  return doPost("/up/admin/reject", params);
};

export const getMyShareList = (page, params) => {
  params["page"] = page;

  return doPost("/up/index/mysharelist", params);
};

// 董事会首页
export const prepareInvite = (params) => {
  return doPost("/up/index/prepareInvite", params);
};
