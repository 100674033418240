<template>
  <div class="popup" @touchmove.stop.prevent>
    <!-- <div class="popup-card panel-card">

      <div class="title" v-if="pid == 0">加入星系计划</div>
      <div class="title" v-if="pid > 0">您已经加入星系计划</div>

      <div class="content">
        <input type="text" v-model="refer" placeholder="请输入推荐人的地址" />
      </div>
      <div class="tool">
        <div class="primary-btn" @click="add_refer">确定</div>
      </div>
      <div class="close-btn" @click="handleClose">
        <img src="@/assets/images/new-close.png" alt="" />
      </div>
    </div> -->
    <modal-pop ref="modal" @confirm="add_refer" @cancel="handleClose">
      <div class="fc">
        <input
          class="invate_address_input"
          v-model="refer"
          type="text"
          placeholder="请输入推荐人地址"
        />
      </div>
    </modal-pop>
  </div>
</template>
<script>
import { doAddRefer } from "../../api";
import { mapState } from "vuex";
import { doGetPid } from "@/api/index";
import modalPop from "@/components/modal-pop/modal-pop.vue";

export default {
  components: {
    modalPop,
  },
  computed: {
    ...mapState({
      address: (state) => state.address,
    }),
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      value: 100,
      lp: "",
      kit: null,
      canApprove: true,
      balance: null,
      provider: null,
      list: null,
      refer: "",
      paddress: "",
      pid: 0,
    };
  },
  created() {
    this.getPid();
  },
  mounted() {},
  methods: {
    async getPid() {
      let r = await doGetPid();
      if (r.code == 0) {
        this.pid = r.result.pid;
        this.paddress = r.result.address;
        if (this.pid > 0) {
          this.refer = r.result.address;
          let key = "wcrkrefer:" + this.address;

          console.log(key);
          localStorage.setItem(key, 1);
          this.$store.commit("setPid", r.result.pid);
        }
        this.$refs.modal.Open({
          title: this.pid == 0 ? "加入星系计划" : "您已经加入星系计划",
          modalType: 3,
          showCancelBtn: false,
		  disableCancel:true
        });
      }
    },

    async add_refer() {
      let params = {
        refer: this.refer,
      };
      let ret = await doAddRefer(params);
      if (ret.code == 0) {
        let key = "wcrkrefer:" + this.address;

        console.log(key);
        localStorage.setItem(key, 1);
        let pid = ret.result.pid;
        if (pid > 0) {
          this.$store.commit("setPid", ret.result.pid);
        }

        this.$toast.success(ret.msg);
        this.handleClose();
      } else {
        this.$toast.fail(ret.msg);
        // this.handleClose();
      }
    },
    handleChange(value) {
      this.value = value;
    },

    handleClose() {
      this.$emit("close");
    },
    // 点击确认
    handleOk() {
      this.$emit("ok");
    },
  },
};
</script>
<style lang="scss" scoped>
.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 50;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.15);
  margin: 0 auto;
  max-width: 1025px;

  .popup-card {
    margin-left: 0.4324rem;
    margin-right: 0.4324rem;
    padding: 0.9189rem 0.5676rem;
    width: 100%;
    z-index: 20;
    margin-top: 5.4054rem;
    border-radius: 0.5946rem;
    max-height: 6.7568rem;
    position: relative;

    .title {
      font-size: 0.4324rem;
      font-weight: 500;
      color: #333;
      line-height: 0.5135rem;
      text-align: center;
      margin-bottom: 1rem;
    }

    .content {
      position: relative;
      font-size: 0.32rem;
      padding: 0.3784rem 0.3243rem;
      border-radius: 0.1351rem;
      border: 0.027rem solid #2448ff;
      background: #d8fff2;
      box-shadow: inset 0 0.0541rem 0.2703rem 0.027rem #1fffb5;
      margin-bottom: 0.8108rem;

      input {
        outline: none;
        border: none;
        width: 100%;
        height: 100%;
        background: transparent;
      }
    }

    .tool {
      display: flex;
      justify-content: center;
      align-items: center;

      .primary-btn {
        width: 100%;
        text-align: center;
        border-radius: 0.2162rem;
        color: #000000;
        font-weight: bold;
        font-size: 0.3784rem;
        padding: 0.3243rem 1.8919rem;
        border: 0.027rem solid #2422f4;
        background: rgb(166, 255, 225);
      }
    }

    .close-btn {
      position: absolute;
      top: 0.2973rem;
      right: 0.2973rem;
      cursor: pointer;

      img {
        width: 0.6486rem;
        height: 0.6486rem;
      }
    }
  }
}
</style>
